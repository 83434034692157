import { useQuery } from '@tanstack/react-query';

import { appointmentApi } from 'service/api/appointmentApi';

export const useGetBooking = () => {
  const advisorService = appointmentApi();

  return useQuery({
    queryKey: [`get-booking`],
    queryFn: async () => advisorService.getBooking(),
    select: (response) => response?.data,
  });
};
