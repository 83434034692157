import { DxCard, DxCardContent, DxContainer } from '@dvag/design-system-react';
import { useMemo, useState } from 'react';
import type { PersonInHouseholdDto } from 'graphql/person/generates';
import type { Params } from 'react-router-dom';
import type { SelectedHousehold } from 'type/household';
import { HouseholdHeader } from './component/HouseholdHeader';
import { HouseholdList } from './component/HouseholdList';
import { HouseholdListAccordion } from './component/HouseholdListAccordion';
import { ListHeader } from './component/ListHeader';
import { PersonList } from './component/PersonList';
import { useFullNameInRoute } from './hooks/useFullNameInRoute';
import { useHouseholdList } from './service/useHouseholdList';
import { useSearchPerson } from './service/useSearchPerson';

import './style.css';

interface HouseholdWidgetProps {
  match: {
    params: Readonly<Params<string>>;
  };
  personList: PersonInHouseholdDto[];
}

export const HouseholdWidget = ({ match, personList }: HouseholdWidgetProps) => {
  const selectedHouseholdId = match?.params?.householdId;

  const [searchTerm, setSearchTerm] = useFullNameInRoute(match?.params?.fullName);

  const [selectedHousehold, setSelectedHouseHold] = useState<null | SelectedHousehold>(null);

  const {
    error,
    data: households,
    isError,
    isLoading,
    refetch,
  } = useHouseholdList(selectedHouseholdId);
  const {
    error: errorPerson,
    data: searchedPersonList,
    isLoading: isLoadingPerson,
  } = useSearchPerson(searchTerm);

  const handleSearch = (newTerm: string) => setSearchTerm(newTerm);
  const hasError = useMemo(() => isError || !!error, [error, isError]);
  const personIdNotFound = useMemo(() => errorPerson?.status === 404, [errorPerson]);

  const hoveringAbove =
    isLoading || (searchTerm.length > 0 && !personIdNotFound) ? 'accordion-container' : '';
  const loadingClass = isLoadingPerson || searchedPersonList?.length ? 'loading-container' : '';
  const reloadList = (e: React.MouseEvent<HTMLDxAccordionElement, MouseEvent>) => {
    const open = !!(e?.target as HTMLDxAccordionElement)?.open;
    if (open && (households.length === 0 || searchedPersonList?.length === 0)) {
      refetch();
    }
  };

  return (
    <div data-testid="household-widget" className="search-widget">
      {!selectedHouseholdId ? (
        <DxCard className="search-widget-content-card">
          <DxCardContent className="search-widget-content">
            <ListHeader
              count={searchedPersonList?.length}
              handleSearch={handleSearch}
              initialSearchTerm={searchTerm}
              isLoading={isLoadingPerson}
              hasError={!!errorPerson}
              formError={errorPerson}
            />
            <HouseholdListAccordion
              count={households.length}
              hidden={searchTerm.length > 0 && !personIdNotFound}
              isLoading={isLoading}
              name={searchTerm}
              reloadList={reloadList}
            >
              <DxContainer color="transparent" className={`household-container ${hoveringAbove}`}>
                {(households.length || searchedPersonList?.length || isLoading) && (
                  <DxContainer color="transparent" className={`scrollable-items ${loadingClass}`}>
                    {searchTerm && !personIdNotFound ? (
                      <PersonList
                        numOfResults={searchedPersonList?.length}
                        hasError={!!errorPerson}
                        isLoading={isLoadingPerson}
                        personList={searchedPersonList}
                      />
                    ) : (
                      <HouseholdList
                        numOfResults={households.length}
                        hasError={hasError}
                        isLoading={isLoading}
                        households={households}
                        setSelectedHouseHold={setSelectedHouseHold}
                      />
                    )}
                  </DxContainer>
                )}
              </DxContainer>
            </HouseholdListAccordion>
          </DxCardContent>
        </DxCard>
      ) : (
        <HouseholdHeader
          household={
            (selectedHousehold &&
              households.length &&
              households.find((hh) => hh.householdId === selectedHouseholdId)) ||
            undefined
          }
          personList={personList}
          selectedHouseholdId={selectedHouseholdId}
        />
      )}
    </div>
  );
};
