import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BadRequestError, CustomError } from '@dvag/dfs-api-client/error';
import { useRef } from 'react';
import {
  FamilyApprovalTwoFAMutationType,
  KundanzuganTransactionStatusResponse,
} from '../type/twoFactor';
import { useNotification } from '../utils/notification/NotificationContext';
import { familyApprovalTwoFaApi } from '../service/api/familyApprovalTwoFAApi';

export const pollTransactionStatus = async (
  familyApprovalService: ReturnType<typeof familyApprovalTwoFaApi>,
  transactionId: string,
  signal: AbortSignal,
  resolve: (value: KundanzuganTransactionStatusResponse) => void,
  reject: (reason?: unknown) => void,
  startTime: number,
) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  const maxTime = 120000;
  const timeout = 3000;

  const checkStatus = async () => {
    try {
      if (signal.aborted) {
        return;
      }

      const response = await familyApprovalService.kundanzuganTransactionStatusApi(
        { transactionId },
        signal,
      );

      if (response.data.status === 'AKZEPTIERT' || response.data.status === 'ABGELEHNT') {
        clearTimeout(timeoutId);
        resolve(response.data);
      } else if (Date.now() - startTime < maxTime) {
        timeoutId = setTimeout(() => {
          if (!signal.aborted) {
            checkStatus();
          }
        }, timeout);
      } else {
        clearTimeout(timeoutId);
        resolve({ status: 'OFFEN' });
      }
    } catch (error) {
      reject(error);
    }
  };

  await checkStatus();
};

export const useFamilyApprovalTwoFa = () => {
  const { showError } = useNotification();
  const queryClient = useQueryClient();
  const familyApprovalTFAService = familyApprovalTwoFaApi();
  const abortControllerRef = useRef<AbortController | null>(null);

  const mutation = useMutation<
    KundanzuganTransactionStatusResponse,
    CustomError,
    FamilyApprovalTwoFAMutationType,
    unknown
  >({
    mutationFn: async ({
      keycloakId,
      personListOlderEighteen,
      householdId,
      advisorId,
      duration,
    }) => {
      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      if (typeof keycloakId === 'undefined' || typeof householdId === 'undefined') {
        throw new BadRequestError('Invalid keycloakId');
      }

      const householdDetails = await familyApprovalTFAService.kundanzuganHaushaltDetailApi(
        { householdId, advisorId },
        signal,
      );
      const personListListId = personListOlderEighteen.map((item) => item.id);
      const members = householdDetails.data
        .filter((item) => personListListId.includes(item.kundennummer))
        .map((item) => `${item.vorname} ${item.nachname}`);

      const approvalResponse = await familyApprovalTFAService.sendFamilyApprovalTwoFA(
        {
          members,
          keycloakId,
          duration,
        },
        signal,
      );

      return new Promise<KundanzuganTransactionStatusResponse>((resolve, reject) => {
        pollTransactionStatus(
          familyApprovalTFAService,
          approvalResponse.data,
          signal,
          resolve,
          reject,
          Date.now(),
        );
      });
    },
    onSuccess: async (_payloadSuccess, { keycloakId }) => {
      await queryClient.invalidateQueries({ queryKey: [`familyApprovalTwoFa-${keycloakId}`] });
    },
    onError: (err: CustomError) => {
      showError({ status: err?.status });
    },
    onSettled: () => {
      abortControllerRef.current = null;
    },
  });

  const abort = () => {
    abortControllerRef?.current?.abort();
  };

  return {
    ...mutation,
    abort,
  };
};
