import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DxButton,
  DxCard,
  DxContainer,
  DxGoldenStage,
  DxHeroImage,
  DxSpacer,
  DxTabBar,
  DxTabPage,
  DxText,
} from '@dvag/design-system-react';
import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { navigatePrevious } from '@dvag/dfs-orchestrator-client/messengers';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';
import i18next from 'i18next';

import { useGetAdvisor } from 'hooks/useGetAdvisor';
import { useGetBooking } from 'hooks/useGetBooking';
import { buildPath } from 'service/buildPath';
import { route } from 'config/route';
import { QRCodeComponent } from '../QRCodeComponent/QRCodeComponent';
import { RecommendationBasicPageActionButtons } from '../RecommendationBasicPageActionButtons/RecommendationBasicPageActionButtons';
import { RecommendationBasicPageTeambuildingCard } from '../RecommendationBasicPageTeambuildingCard/RecommendationBasicPageTeambuildingCard';
import { checkIcon } from '../../../../utils/util';
import '../../style.css';

interface RecommendationBasicPageProps {
  teamBuilding: boolean;
}

export const RecommendationBasicPage = ({ teamBuilding }: RecommendationBasicPageProps) => {
  const heroImage = '/assets/images/recommendationPage/RecommendationBasicPageImage.png';

  const { householdId, selectedSection } = useParams();

  const { data: advisorInfo } = useGetAdvisor();

  const [advisorUrl, setAdvisorUrl] = useState<string>('');
  const [teambuildingUrl, setTeambuildingUrl] = useState<string>('');

  useEffect(() => {
    if (advisorInfo?.urlList?.homepage) {
      if (!teamBuilding) {
        setAdvisorUrl(`https://${advisorInfo.urlList.dreamJob}`);
      } else {
        setAdvisorUrl(`${advisorInfo.urlList.teamBuilding}`);
        setTeambuildingUrl(`https://${advisorInfo.urlList.homepage}`);
      }
    } else if (teamBuilding) {
      setAdvisorUrl('https://www.dvag-karriere.de');
    }
  }, [advisorInfo, teamBuilding]);

  const { data: booking } = useGetBooking();

  const translation = {
    recommendation: i18next.t('recommendationPage.recommendation'),
    dreamJob: i18next.t('dreamJobPage.dreamJob'),
    homepageCardText1: i18next.t('recommendationPage.homepageCardText1'),
    homepageCardText2: i18next.t('recommendationPage.homepageCardText2'),
    homepageCardText3: i18next.t('recommendationPage.homepageCardText3'),
    homepageCardTextList1: i18next.t('recommendationPage.homepageCardTextList1'),
    homepageLinkTextList2: i18next.t('recommendationPage.homepageLinkTextList2'),
    homepageLinkTextList3: i18next.t('recommendationPage.homepageLinkTextList3'),
    homepageWhatsappTextList2: i18next.t('recommendationPage.homepageWhatsappTextList2'),
    homepageWhatsappTextList3: i18next.t('recommendationPage.homepageWhatsappTextList3'),
    QRMessageWhatsapp: i18next.t('recommendationPage.QRMessageWhatsapp'),
    dreamJobTextList2: i18next.t('dreamJobPage.dreamJobTextList2'),
    dreamJobCardText1: i18next.t('dreamJobPage.dreamJobCardText1'),
    dreamJobCardText2: i18next.t('dreamJobPage.dreamJobCardText2'),
    homepageLink: i18next.t('recommendationPage.homepageLink'),
    sendingWhatsapp: i18next.t('recommendationPage.sendingWhatsapp'),
    back: i18next.t('recommendationPage.back'),
  };

  const dataTestIdTitle = teamBuilding
    ? 'dream-job-basic-page-page-title'
    : 'recommendation-basic-page-page-title';

  return (
    <DxContainer color="transparent">
      <DxGoldenStage>
        <DxHeroImage
          slot="hero-image"
          mq5={heroImage}
          mq4={heroImage}
          mq3={heroImage}
          mq2={heroImage}
          mq1={heroImage}
        />
        <DxText type="h3" color="white" data-testid={dataTestIdTitle}>
          {teamBuilding ? translation.dreamJob : translation.recommendation}
        </DxText>
      </DxGoldenStage>
      <DxSpacer size="64v" />
      <DxContainer color="transparent" className="recommendation-basic-page-card-container">
        {advisorInfo && teamBuilding && (
          <RecommendationBasicPageTeambuildingCard
            advisorUrl={advisorUrl}
            teambuildingUrl={teambuildingUrl}
            booking={booking}
          />
        )}
        {advisorInfo && !teamBuilding && (
          <DxContainer color="transparent" type="page">
            <DxCard className="recommendation-basic-page-card">
              <DxTabBar
                id="recommendation-tab-bar"
                data-testid="recommendation-tab-bar"
                onTabChange={(e) => {
                  navigateToMicroApp(
                    'berater',
                    buildPath(route.recommendationPageSelectedSection, {
                      householdId: householdId as string,
                      selectedSection: e.target.selected as string,
                    }),
                  );
                }}
                selected={selectedSection || 'homepage'}
                className="recommendation-basic-page-tab-bar"
              >
                <DxTabPage
                  id="homepage"
                  className="recommendation-basic-page-tab-page"
                  value="homepage"
                  label={translation.homepageLink}
                  data-testid="recommendation-basic-page-homepage-tab"
                >
                  <div className="recommendation-basic-page-card-content">
                    <div className="recommendation-basic-page-card-content-text">
                      <DxText>{translation.homepageCardText1}</DxText>
                      <DxSpacer size="16" />
                      <DxText>{translation.homepageCardText2}</DxText> <DxSpacer size="16" />
                      <DxText>{translation.homepageCardText3}</DxText>
                      <DxSpacer size="16" />
                      <DxText>{translation.homepageCardTextList1}</DxText>
                      <DxText>{translation.homepageLinkTextList2}</DxText>
                      <DxText>{translation.homepageLinkTextList3}</DxText>
                    </div>
                    <div className="recommendation-basic-page-card-content-qr">
                      <QRCodeComponent ecLevel="H" url={advisorUrl} />
                    </div>
                  </div>
                  <RecommendationBasicPageActionButtons booking={booking} />
                </DxTabPage>
                <DxTabPage
                  className="recommendation-basic-page-tab-page"
                  value="whatsapp"
                  id="whatsapp"
                  label={translation.sendingWhatsapp}
                  data-testid="recommendation-basic-page-whatsapp-tab"
                >
                  <div className="recommendation-basic-page-card-content">
                    <div className="recommendation-basic-page-card-content-text">
                      <DxText>{translation.homepageCardText1}</DxText> <DxSpacer size="16" />
                      <DxText>{translation.homepageCardText2}</DxText> <DxSpacer size="16" />
                      <DxText>{translation.homepageCardText3}</DxText> <DxSpacer size="16" />
                      <DxText>{translation.homepageCardTextList1}</DxText>
                      <DxText>{translation.homepageWhatsappTextList2}</DxText>
                      <DxText>{translation.homepageWhatsappTextList3}</DxText>
                    </div>
                    <div className="recommendation-basic-page-card-content-qr">
                      <QRCodeComponent
                        ecLevel="M"
                        url={`${advisorInfo.urlList.dreamJobWhatsapp}`}
                      />
                    </div>
                  </div>
                  <RecommendationBasicPageActionButtons booking={booking} />
                </DxTabPage>
              </DxTabBar>
            </DxCard>
          </DxContainer>
        )}

        <DxContainer
          color="transparent"
          type="page"
          className="recommendation-basic-page-back-button"
        >
          <DxButton
            type="text"
            data-testid="recommendation-back-button"
            onClick={() =>
              navigatePrevious(
                buildPath(`/${appNames.advisor}${sharedAppRoutes.advisor.householdDashboard}`, {
                  householdId: householdId as string,
                }),
              )
            }
            label={translation.back}
            icon={checkIcon('pfeil-links')}
            iconposition="left"
          />
        </DxContainer>
      </DxContainer>
    </DxContainer>
  );
};
