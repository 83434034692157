import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import {
  DxCard,
  DxCardContent,
  DxContainer,
  DxIcon,
  DxList,
  DxNotificationToast,
  DxText,
} from '@dvag/design-system-react';
import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';

import i18next from 'i18next';

import { PersonInHouseholdDto } from 'graphql/person/generates';
import { ConsentModal } from 'component/ConsentModal';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { RenderSection, SectionListData } from 'type/sectionList';
import { checkIcon, getConsentStatusTranslation, getPersonFullName, hasApprovedConsent } from 'utils/util';
import { dashboardUtil } from 'service/model/dashboardUtil';
import { Item } from 'component/BottomCards/Card/Item';
import { useGetHouseholdDashboard } from 'hooks/useGetHouseholdDashboard';
import FamilyApprovalWidget from '@dvag/family-approval-widget';
import featureFlag from 'service/featureFlag';
import { isPersonYoungerThan18 } from '@dvag/dfs-ui-blocks/utils/dateUtils';
import './style.css';
import { permissionMap } from 'utils/permission/permissionMap';
import { SharingPeriodType } from 'type/twoFactor';
import { useFamilyApprovalTwoFa } from 'hooks/useFamilyApprovalTwoFa';
import { familyApprovalApi } from 'service/api/familyApprovalApi';
import { useGetAdvisor } from 'hooks/useGetAdvisor';
import { getConsentStatus, useUtil, borderBottom as cardBorder } from './util';

interface dataInterface {
  personId: string;
  status: string;
}

const CLOSE_SECONDS_TIMEOUT = 3;

const {
  PRIVACY_POLICY,
  HHB_AND_CONTRACT_OVERVIEW,
  INTERVIEW_CONCLUSION,
  ADVISOR,
  ACCOUNTING,
  VPD,
  CUSTOMER_ACCESS,
} = dashboardUtil;

export const BottomCard = () => {
  const { householdId, section } = useParams();
  const householdDashboard = useGetHouseholdDashboard();
  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [isSentApprovalSuccess, setIsSentApprovalSuccess] = useState(false);
  const [isSentApprovalFailed, setIsSentApprovalFailed] = useState(false);
  const [isSentApprovalRetry, setIsSentApprovalRetry] = useState(false);
  const [isConsentEmailSent, setIsConsentEmailSent] = useState('');
  const [chosenPerson, setChosenPerson] = useState<PersonInHouseholdDto | undefined>();
  const [personListWithConsent, setPersonListWithConsent] = useState<dataInterface[]>([]);
  const navigate = useNavigate();
  const prevKeycloakIdRef = useRef<{kundenNr: string, keycloakId: string}>();
  const { data: advisorInfo } = useGetAdvisor();
  const familyApprovalService = familyApprovalApi();
  const pathToDashboard = `/haushalt/${householdId}/uebersicht`;
  const personList = useMemo(
    () => householdDashboard?.data?.personList || [],
    [householdDashboard?.data?.personList],
  );
  const isNewKundenportalShown = featureFlag.gb?.isOn(permissionMap.canAccessNewKundenportalFlag);
  const personListOlderEighteen = useMemo(
    () => personList.filter((person) => !isPersonYoungerThan18(person.birthDate as string)),
    [personList],
  );
  const isTwoPersonsOlderEighteen = useMemo(
    () => personListOlderEighteen?.length > 1 && isNewKundenportalShown,
    [personListOlderEighteen?.length, isNewKundenportalShown],
  );
  const {
    disabledBottomList,
    bottomList,
    hhbContract,
    isKundenzugangToolClosed,
    setIsKundenzugangToolClosed,
    openKundenzugangTool,
    customerDetail,
  } = useUtil(householdId as string, isTwoPersonsOlderEighteen);

  const customerPerson = useMemo(() => personList.find(item => item.id === customerDetail?.kundenNr), [customerDetail, personList]);

  const { mutateAsync, abort } = useFamilyApprovalTwoFa();

  useEffect(() => {
    if (!isKundenzugangToolClosed) {
      abort();
    }
  }, [abort, isKundenzugangToolClosed]);

  useEffect(() => {
    prevKeycloakIdRef.current = customerDetail;
  }, [customerDetail]);

  const showConsentModal = () => setConsentModalVisibility(true);

  const hideConsentModal = () => setConsentModalVisibility(false);

  const showSuccessConsentModal = (str: string) => setIsConsentEmailSent(str);

  let list: SectionListData[] = !householdId ? disabledBottomList : bottomList;
  const gdprDataList: SectionListData[] = useMemo(
    () =>
      personList?.map((person, index) => {
        const consent = householdDashboard?.data?.consentList?.find(
          (data) => data?.personId === person.id,
        );
        const consentStatus = getConsentStatus(consent?.status);
        const isLast = personList.length - 1 === index;

        return {
          id: `${person?.id}-gdpr`,
          gdprStatus: consentStatus,
          householdId,
          personId: person?.id,
          path: PRIVACY_POLICY,
          title: getPersonFullName(person),
          icon: 'mensch',
          borderBottom: !isLast ? cardBorder : 'none',
          renderRightSection: () => (
            <div data-testid={`consent-status-${index}`}>
              {consentStatus && (
                <div className="gdpr-status">
                  <DxText type="Infotext-Standard" color="gray-66" style={{ padding: 0 }}>
                    {getConsentStatusTranslation(consentStatus.descriptionKey)}
                  </DxText>
                  <DxContainer
                    color="white"
                    className="gdpr-icon flex row align-center justify-center"
                    style={{ color: '#337a96' }}
                  >
                    <consentStatus.Icon />
                  </DxContainer>
                </div>
              )}
            </div>
          ),
        };
      }),
    [householdDashboard?.data?.consentList, householdId, personList],
  );

  if (section === PRIVACY_POLICY) {
    list = gdprDataList;
  }

  if (section === HHB_AND_CONTRACT_OVERVIEW) {
    list = hhbContract;
  }

  const onSendApproval = useCallback(
    async (duration: SharingPeriodType) => {

      try {
        setIsSentApprovalSuccess(false);
        setIsSentApprovalFailed(false);
        setIsSentApprovalRetry(false);
        const keycloakRef = prevKeycloakIdRef.current;

        const response = await mutateAsync({
          keycloakId: keycloakRef?.keycloakId,
          customerPersonId: keycloakRef?.kundenNr,
          personListOlderEighteen,
          duration,
          householdId,
          advisorId: advisorInfo?.id,
        });
        switch (response.status) {
          case 'AKZEPTIERT':
            setIsSentApprovalSuccess(true);
            await familyApprovalService.sendFamilyApproval({
              householdId,
              customerPersonId: keycloakRef?.kundenNr,
              duration,
            });
            break;
          case 'ABGELEHNT':
            setIsSentApprovalFailed(true);
            break;
          case 'OFFEN':
            setIsSentApprovalRetry(true);
            break;
          default:
            setIsSentApprovalSuccess(true);
        }
      } catch (err) {
        setIsSentApprovalFailed(true);
      }
    },
    [advisorInfo?.id, familyApprovalService, householdId, mutateAsync, personListOlderEighteen],
  );

  const onItemClick = (path: string, id?: string | null) => {
    if (!householdId) return;
    if (section === HHB_AND_CONTRACT_OVERVIEW) {
      handleNavigateToPath(path, householdId, ACCOUNTING);
    } else {
      switch (path) {
        case INTERVIEW_CONCLUSION:
          handleNavigateToPath(
            generatePath(sharedAppRoutes.vpd.analysis.appointmentCompletion, {
              legacyHouseholdId: householdId,
            }),
            '',
            VPD,
          );
          break;
        case CUSTOMER_ACCESS:
          openKundenzugangTool();
          break;
        case PRIVACY_POLICY:
          if (id?.length) {
            const selectedPerson = personList?.find((person) => id === person.id);
            showConsentModal();
            setChosenPerson(selectedPerson);
          }
          handleNavigateToPath(`${pathToDashboard}/sektion/${path}`, '', ADVISOR);
          break;
        default:
          handleNavigateToPath(`${pathToDashboard}/sektion/${path}`, '', ADVISOR);
      }
    }
  };

  useEffect(() => {
    if (householdDashboard?.data?.consentList) {
      const listOfConsentGiven = householdDashboard?.data?.consentList?.filter(({ status }) =>
        hasApprovedConsent(status),
      );
      setPersonListWithConsent(listOfConsentGiven);
    }
  }, [householdDashboard?.data?.consentList]);

  useEffect(() => {
    if (section !== PRIVACY_POLICY) {
      hideConsentModal();
    }
  }, [section]);

  const buildPageTitle = useCallback(
    (path?: string, title?: string, id?: string) => {
      const consentCount = householdDashboard?.data?.consentList?.length
        ? `(${personListWithConsent?.length}/${personList?.length})`
        : '';
      const privacyTitle = `${i18next.t('dashboard.bottomList.gdpr')} ${consentCount}`;
      const kundezugangTitle = `${i18next.t('dashboard.bottomList.kundenzugang')}`;
      if (
        path === CUSTOMER_ACCESS &&
        id === CUSTOMER_ACCESS &&
        !!householdDashboard?.data?.consentList?.length
      ) {
        return kundezugangTitle;
      }
      if (
        path === PRIVACY_POLICY &&
        id === bottomList[0].id &&
        !!householdDashboard?.data?.consentList?.length
      ) {
        return privacyTitle;
      }
      return title;
    },
    [
      householdDashboard?.data?.consentList?.length,
      personListWithConsent?.length,
      personList?.length,
      bottomList,
    ],
  );

  const chosenPersonConsentStatus =
    !!chosenPerson &&
    householdDashboard?.data?.consentList?.find((data) => data?.personId === chosenPerson.id)
      ?.status;

  const translation = {
    success: i18next.t('sendConsent.success'),
    basicFunctions: i18next.t('dashboard.basicFunctions'),
  };

  return (
    <DxCard style={{ overflow: 'hidden' }} data-testid="bottom-card-container">
      {householdId && <kundenzugang-webcomponent />}
      {chosenPerson && (
        <ConsentModal
          visible={consentModalVisibility}
          onClosed={hideConsentModal}
          person={chosenPerson}
          consent={householdDashboard?.data?.consentList.find(
            (consent) => consent.personId === chosenPerson.id,
          )}
          isConsentFetching={householdDashboard?.isLoading || householdDashboard?.isRefetching}
          onSubmitSuccess={hideConsentModal}
          personConsentStatus={chosenPersonConsentStatus as string}
          setIsConsentEmailSent={showSuccessConsentModal}
        />
      )}
      <DxNotificationToast
        id="consent-email-success"
        data-testid="consent-email-success"
        visible={!!isConsentEmailSent}
        icon={checkIcon('check-kreis')}
        iconcolor="green-80"
        style={{ zIndex: 10000 }}
        closeafter={CLOSE_SECONDS_TIMEOUT}
        onCloseNotification={() => {
          setIsConsentEmailSent('');
        }}
      >
        <div className="notification-text-container" data-testid="notification-success">
          <DxText>{translation.success}</DxText>
        </div>
      </DxNotificationToast>
      <DxContainer color="white" className="title-container">
        {section !== HHB_AND_CONTRACT_OVERVIEW && section !== PRIVACY_POLICY && (
          <DxText type="ps" className="bottom-list-title">
            {translation.basicFunctions}
          </DxText>
        )}
        {section === HHB_AND_CONTRACT_OVERVIEW && (
          <DxText
            onClick={() => handleNavigateToPath(`${pathToDashboard}`, '', ADVISOR)}
            className="bottom-list-title"
          >
            <DxIcon color="default" icon={checkIcon('pfeil-links')} size={24} className="chevron" />
            {i18next.t('dashboard.bottomList.hhbAndContractOverview')}
          </DxText>
        )}
        {section === PRIVACY_POLICY && (
          <DxText onClick={() => navigate(pathToDashboard)} className="bottom-list-title">
            <DxIcon color="default" icon={checkIcon('pfeil-links')} size={24} className="chevron" />
            {i18next.t('sendConsent.dataProtection')}
          </DxText>
        )}
      </DxContainer>
      <DxCardContent className="dashboard-card-content">
        <div className="flex column">
          <DxList size="m">
            {list?.map(({ title, icon, path, id }, index) => (
              <Item
                id={section === PRIVACY_POLICY ? `person-${index}` : id}
                key={section === PRIVACY_POLICY ? `person-${index}` : id}
                onItemClick={() => onItemClick(path, list?.[index]?.personId)}
                title={buildPageTitle(path, title, id)}
                cursorStyle={householdId ? 'pointer' : 'default'}
                icon={icon}
                renderRightSection={(list?.[index] as RenderSection)?.renderRightSection}
              />
            ))}
          </DxList>
        </div>
      </DxCardContent>
      {isTwoPersonsOlderEighteen && customerPerson && (
        <FamilyApprovalWidget
          isSentApprovalSuccess={isSentApprovalSuccess}
          isSentApprovalFailed={isSentApprovalFailed}
          isSentApprovalRetry={isSentApprovalRetry}
          customerPerson={customerPerson}
          onClick={onSendApproval}
          isOpen={isKundenzugangToolClosed}
          setIsOpen={setIsKundenzugangToolClosed}
          data-testid="family-approval-widget"
          personListWithConsent={personListOlderEighteen}
        />
      )}
    </DxCard>
  );
};
