import React from 'react';
import { DxCard, DxCardContent } from '@dvag/design-system-react';

import i18next from 'i18next';

import { NoResult } from './NoResult';

export const ErrorLoadingList = () => (
  <DxCard className="error-loading-card" data-testid="error-loading-list-container">
    <DxCardContent className="error-loading-card-content">
      <NoResult id="error-loading-list" message={i18next.t('dashboard.search.requestError')} />
    </DxCardContent>
  </DxCard>
);
