import { QueryClient } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';

export interface TError extends Error {
  response: {
    status: number;
  };
  config: {
    url: string;
  };
  cause: number;
}

const maxFailureCount = 5;

const checkException = (error: TError | unknown) =>
  ((error as TError).response?.status === HttpStatusCode.Forbidden &&
    ![
      '/booking',
      '/one-note?moduleType=KundenWerbenKunden',
      '/one-note?moduleType=Teamaufbau',
    ].includes((error as TError)?.config?.url)) ||
  (error as TError).response?.status === HttpStatusCode.Unauthorized;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: (failureCount, error) =>
        failureCount <= maxFailureCount &&
        ![HttpStatusCode.Forbidden, HttpStatusCode.Unauthorized].includes(
          (error as TError).response?.status,
        ),
      throwOnError: (error) => checkException(error),
    },
    mutations: {
      throwOnError: (error) => checkException(error),
    },
  },
});
