import { appNames } from '@dvag/dfs-constant-config/app-names';
import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { DxCard, DxCardContent, DxContainer, DxList } from '@dvag/design-system-react';

import { buildPath } from 'service/buildPath';
import { route } from 'config/route';
import { PersonSearchItem } from 'type/person';

import { Loading } from './Loading';
import { ErrorLoadingList } from './ErrorLoadingList';
import { MatchedPerson } from './person';

import './style.css';

interface PersonListProps {
  hasError: boolean | undefined;
  personList: PersonSearchItem[] | undefined;
  isLoading: boolean;
  numOfResults: number | undefined;
}

export const PersonList = ({ hasError, personList, isLoading, numOfResults }: PersonListProps) => {
  const viewPerson = (person: PersonSearchItem) => {
    const path = buildPath(route.placeholder, { selectedPersonId: person.id });
    navigateToMicroApp(appNames.advisor, path);
  };

  if (isLoading) return <Loading />;
  if (hasError || (numOfResults && numOfResults > 100)) return <ErrorLoadingList />;
  if (!personList?.length) return null;

  return (
    <DxCard>
      <DxCardContent className="person-list-content">
        <DxList size="l">
          {personList?.map((person) => (
            <DxContainer color="white" className="pointer" key={person.uuid}>
              <MatchedPerson
                data-testid={`view-person-${person.id}`}
                person={person}
                onClick={() => viewPerson(person)}
              />
            </DxContainer>
          ))}
        </DxList>
      </DxCardContent>
    </DxCard>
  );
};
